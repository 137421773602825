import cx from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useEditContext,
  usePageContext,
  ExternalEditInfoBanner,
} from '@xing-com/crate-entity-pages-common';
import { trackContentSwitcherFirstUse } from '@xing-com/crate-entity-pages-common/src/tracking';
import Observer from '@xing-com/intersection-observer';

import { BRANDMANAGER_URL } from '../../config/urls';
import * as Styled from './navigation.styles';

type NavigationProps = {
  pageModulesByType: Array<string>;
  navigationRef: (node: any) => void;
  navigationOffset: number;
  frameSize: {
    width: number;
    height: number;
  };
  activeIndex: number;
  forceActiveIndex: (activeIndex: number) => void;
  navigationBarHeight: number;
};

export const Navigation: FC<NavigationProps> = ({
  pageModulesByType,
  navigationRef,
  navigationOffset,
  frameSize,
  activeIndex,
  forceActiveIndex,
  navigationBarHeight,
}) => {
  const intl = useIntl();
  const { isEditing, isEditor } = useEditContext();
  const [isSticky, setSticky] = useState(false);
  const [firstNavClick, setFirstNavClick] = useState(true);

  const { pageContext } = usePageContext() ?? {};

  const scrollToModule = (type: string) => {
    const moduleOffset =
      window.pageYOffset +
      document.getElementById(`module-${type}`)!.getBoundingClientRect().top;
    const margin = 16;

    forceActiveIndex(
      pageModulesByType.findIndex((pageModuleType) => pageModuleType === type)
    );

    if (firstNavClick) {
      trackContentSwitcherFirstUse(type);
      setFirstNavClick(false);
    }

    window.scrollTo({
      top: moduleOffset - navigationOffset - margin,
      behavior: 'smooth',
    });
  };

  const data = pageModulesByType.map((pageModuleType) => ({
    copy: intl.formatMessage({
      id: 'EP_' + pageModuleType.toUpperCase() + '_NAVIGATION_TITLE',
    }),
    ['data-testid']: `EP-${pageModuleType.toUpperCase()}-NAVIGATION`,
    onClick: () => module && scrollToModule(pageModuleType),
  }));

  return (
    <Styled.NavigationWrapper isEditing={isEditing}>
      <Observer
        onChange={({
          rootBounds,
          boundingClientRect,
        }: IntersectionObserverEntry) =>
          rootBounds && setSticky(boundingClientRect.y < rootBounds.y)
        }
        rootMargin={`-${frameSize.height}px 0px 0px 0px`}
        threshold={[1]}
      >
        <div className="navigationSentinal" />
      </Observer>
      <div
        style={{
          height: navigationBarHeight > 0 ? navigationBarHeight : 'inherit',
        }}
      >
        <Styled.Navigation
          className={cx({
            navigationSticky: isSticky,
            isEditing: isEditing,
            isEditor: isEditor,
            notDraft: pageContext?.publicationStatus !== 'DRAFT',
          })}
          style={{
            height: navigationBarHeight > 0 ? navigationBarHeight : 'inherit',
            width: frameSize.width > 0 ? frameSize.width + 40 : 'inherit',
          }}
        >
          <Styled.NavigationContainer ref={navigationRef}>
            <Styled.ContentSwitcherContainer>
              <Styled.ContentSwitcher
                activeItemIndex={activeIndex}
                variant="filled"
                size="small"
                isFixed={isSticky}
                data={data}
              />
            </Styled.ContentSwitcherContainer>
          </Styled.NavigationContainer>
          <Styled.NavigationBorder />
        </Styled.Navigation>
      </div>

      {isEditing && pageContext?.publicationStatus !== 'DRAFT' && (
        <Styled.InfoBanner>
          <ExternalEditInfoBanner
            copyKey="EP_NAVIGATION_EDIT_HINT"
            ctaCopyKey="EP_NAVIGATION_EDIT_HINT_CTA"
            ctaUrl={BRANDMANAGER_URL(pageContext?.pageSlug)}
            cyTestId="EDIT_HINT_NAVIGATION"
            ctaAvailableInMobile={false}
          />
        </Styled.InfoBanner>
      )}
    </Styled.NavigationWrapper>
  );
};
