import { FormattedMessage } from 'react-intl';

import type { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';
import { FormattedTimestamp } from '@xing-com/crate-entity-pages-common';

import { ArticleState } from '../article-state/article-state';
import {
  ArticleCardTopWrapper,
  ButtonsContainer,
  InfoContainer,
} from './article-card-top.styles';

type Props = {
  isFeatured: boolean;
  isEditView: boolean;
  publicationState: ContentArticleStatus;
  publicationDate?: string;
};

export const ArticleCardTop: React.FC<Props> = ({
  isFeatured,
  isEditView,
  publicationState,
  publicationDate,
}) => {
  //TODO: check publicationDate with BE
  return (
    <ArticleCardTopWrapper>
      <InfoContainer>
        {isEditView ? (
          <span>
            <ArticleState state={publicationState} />
          </span>
        ) : null}
        {publicationDate && (
          <span>
            <FormattedTimestamp date={new Date(publicationDate)} />
          </span>
        )}
        {isFeatured ? (
          <strong>
            <FormattedMessage id="EP_ARTICLES_MODULE_PINNED_LABEL" />
          </strong>
        ) : null}
      </InfoContainer>
      {isEditView ? (
        // TODO: https://new-work.atlassian.net/browse/PROCO-1364
        <ButtonsContainer>TODO: PROCO-1364</ButtonsContainer>
      ) : null}
    </ArticleCardTopWrapper>
  );
};
