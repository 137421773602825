import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { space3XL, spaceS, spaceXL, xdlColorInfoSoft } from '@xing-com/tokens';

import { FilterBar as DefaultFilterBar } from '../components/filter-bar/filter-bar';

export const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${space3XL};
`;

export const HeaderEditButton = styled(Button)`
  background-color: ${xdlColorInfoSoft};
  margin-left: ${spaceS};
`;

export const ArticlesModuleFooter = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${space3XL};
`;

export const FilterBar = styled(DefaultFilterBar)`
  margin-bottom: ${spaceXL};
`;
