import { useMemo } from 'react';

export const ScriptSource = {
  PRODUCTION:
    'https://recruiting.xing.com/wp-content/plugins/matomo/app/../../../uploads/matomo/container_KclFfkei.js',
  PREVIEW:
    'https://recruiting.xing.com/wp-content/plugins/matomo/app/../../../uploads/matomo/container_uSoRE1ko.js',
};

export const getScript = (env) => {
  return useMemo(() => {
    if (env === 'production') {
      return ScriptSource.PRODUCTION;
    }
    return ScriptSource.PREVIEW;
  }, [env]);
};
