import { FormattedMessage } from 'react-intl';

import type { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';
import { IconClock, IconHide, IconTick } from '@xing-com/icons';

import { ICON_DIMENSION } from '../constants';
import {
  HiddenFlag,
  PublishedFlag,
  ScheduledFlag,
} from './article-state.styles';

type Props = { state: `${ContentArticleStatus}` };

export const ArticleState: React.FC<Props> = ({ state }) => {
  switch (state) {
    case 'DISABLED':
      return (
        <HiddenFlag>
          <IconHide width={ICON_DIMENSION} height={ICON_DIMENSION} />
          <span>
            <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-HIDDEN" />
          </span>
        </HiddenFlag>
      );
    case 'DRAFT':
      return (
        <strong>
          <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-DRAFTS" />
        </strong>
      );
    case 'PUBLISHED':
      return (
        <PublishedFlag>
          <IconTick width={ICON_DIMENSION} height={ICON_DIMENSION} />
          <span>
            <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-PUBLISHED" />
          </span>
        </PublishedFlag>
      );
    case 'SCHEDULED':
      return (
        <ScheduledFlag>
          <IconClock width={ICON_DIMENSION} height={ICON_DIMENSION} />
          <span>
            <FormattedMessage id="EP-ARTICLES-MODULE-FILTER-SCHEDULED" />
          </span>
        </ScheduledFlag>
      );
  }
};
