import { useMutation } from '@apollo/client';
import type { DataProxy } from '@apollo/client/cache';
import type { FC } from 'react';

import type { ShowErrorProps } from '@xing-com/crate-entity-pages-common';
import {
  redirectToLogin,
  useErrorContext,
  useLoggedoutAction,
} from '@xing-com/crate-entity-pages-common';
import { trackAffiliatesFollowAction } from '@xing-com/crate-entity-pages-common/src/tracking';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import { LIMIT_AFFILIATES_LIST } from '../../config';
import { FollowCompanyMutationDocument } from '../../graphql/mutation/affiliates-follow-company.gql-types';
import { UnfollowCompanyMutationDocument } from '../../graphql/mutation/affiliates-unfollow-company.gql-types';
import { AffiliatesDocument } from '../../graphql/queries/affiliates-query.gql-types';
import AffiliatesItem from './affiliates-item';
import type {
  AffiliatesFollowCompanyMutationType,
  AffiliatesUnFollowCompanyMutationType,
  AffiliatesEdgesType,
} from './types';

const handleCache =
  (
    pageSlug: string,
    followedId: string,
    showError: (error: ShowErrorProps) => void
  ) =>
  (
    cache: DataProxy,
    mutationResult?:
      | (AffiliatesFollowCompanyMutationType &
          AffiliatesUnFollowCompanyMutationType)
      | null
  ) => {
    if (
      mutationResult?.companyFollow?.error?.message ||
      mutationResult?.companyUnfollow?.error?.message
    ) {
      return showError({
        message: 'EP_JOBS_FEEDBACK_ERROR',
        error:
          mutationResult?.companyFollow?.error?.message ||
          mutationResult?.companyUnfollow?.error?.message,
      });
    }

    const data = cache.readQuery({
      query: AffiliatesDocument,
      variables: {
        id: pageSlug,
        limit: LIMIT_AFFILIATES_LIST,
      },
    });

    // @ts-expect-error TODO: fix this type
    const edges = data?.pagesAboutUsFacts?.companyData?.affiliates?.edges.map(
      ({ node }: AffiliatesEdgesType) => {
        if (node.affiliateCompany.id === followedId) {
          const result = {
            node: {
              ...node,
              affiliateCompany: {
                ...node.affiliateCompany,
                followers: {
                  ...node?.affiliateCompany?.followers,
                  total: node.affiliateCompany.userContext.followState
                    .isFollowing
                    ? node?.affiliateCompany?.followers?.total - 1
                    : node?.affiliateCompany?.followers?.total + 1,
                },
                userContext: {
                  ...node?.affiliateCompany?.userContext,
                  followState: {
                    ...node?.affiliateCompany?.userContext?.followState,
                    isFollowing:
                      !node.affiliateCompany.userContext.followState
                        .isFollowing,
                  },
                },
              },
            },
            __typename: 'CompanyEmployeesEdge',
          };
          return result;
        }
        return { node, __typename: 'CompanyEmployeesEdge' };
      }
    );

    cache.writeQuery({
      query: AffiliatesDocument,
      variables: {
        id: pageSlug,
        limit: LIMIT_AFFILIATES_LIST,
      },
      data: {
        pagesAboutUsFacts: {
          ...data?.pagesAboutUsFacts,
          companyData: {
            // @ts-expect-error TODO: fix this type
            ...data?.pagesAboutUsFacts.companyData,
            affiliates: {
              // @ts-expect-error TODO: fix this type
              ...data?.pagesAboutUsFacts.companyData.affiliates,
              edges,
            },
          },
        },
      },
    });
  };

type AffiliatesItemContainerProps = {
  id: string;
  pageSlug: string;
  displayName: string;
  category: string;
  followers: number;
  url: string;
  logo: string;
  isFollowing: boolean;
  serverData?: {
    authenticated?: boolean;
  };
};
export const AffiliatesItemContainer: FC<AffiliatesItemContainerProps> = ({
  id: followedId,
  pageSlug,
  isFollowing,
  ...rest
}) => {
  const { isLoggedIn } = useLoginState();
  const { showError } = useErrorContext();

  const executeAffiliatesFollowCompany = handleCache(
    pageSlug,
    followedId,
    showError
  );

  const [handleFollowCompany, { loading: followMutationLoading }] = useMutation(
    FollowCompanyMutationDocument,
    {
      optimisticResponse: {
        companyFollow: {
          error: null,
        },
      },
      // @ts-expect-error TODO: fix this type
      update: (cache, { data }) => executeAffiliatesFollowCompany(cache, data),
      onError: (error) =>
        showError({
          message: 'EP_AFFILIATES_FEEDBACK_ERROR',
          error,
        }),
    }
  );

  const [handleUnfollowCompany, { loading: unfollowMutationLoading }] =
    useMutation(UnfollowCompanyMutationDocument, {
      optimisticResponse: {
        companyUnfollow: {
          error: null,
        },
      },
      // @ts-expect-error TODO: fix this type
      update: (cache, { data }) => executeAffiliatesFollowCompany(cache, data),
      onError: (error) =>
        showError({
          message: 'EP_AFFILIATES_FEEDBACK_ERROR',
          error,
        }),
    });

  const toggleFollowCompany = () => {
    const mutationIsLoading = followMutationLoading || unfollowMutationLoading;

    if (!isLoggedIn) {
      redirectToLogin(`followEntityPage${followedId}`);
    }

    if (mutationIsLoading) return;

    trackAffiliatesFollowAction(!isFollowing);

    !isFollowing
      ? handleFollowCompany({
          variables: {
            input: {
              followedId,
            },
          },
        })
      : handleUnfollowCompany({
          variables: {
            input: {
              followedId,
            },
          },
        });
  };

  useLoggedoutAction(
    toggleFollowCompany,
    `followEntityPage${followedId}`,
    isFollowing
  );

  return (
    <AffiliatesItem
      toggleFollowCompany={toggleFollowCompany}
      isFollowing={isFollowing}
      authenticated={isLoggedIn}
      {...rest}
    />
  );
};
