import { FormattedMessage } from 'react-intl';

import { useEditContext } from '@xing-com/crate-entity-pages-common';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

import type { ArticleCardView } from '../../types/types';
import { ArticleCardBottom } from './article-card-bottom/article-card-bottom';
import { ArticleCardTop } from './article-card-top/article-card-top';
import {
  Content,
  ImageContainer,
  TextContainer,
  Headline,
  Summary,
  ArticlePage,
  Link,
} from './article-card.styles';

type Props = {
  article: ArticleCardView;
};

export const ArticleCard: React.FC<Props> = ({ article }): JSX.Element => {
  const { isLoggedIn } = useLoginState();
  const { isEditing } = useEditContext();
  const isEditView = isEditing && isLoggedIn;
  const {
    content,
    publicationState,
    publishedAt,
    socialInteractions,
    isFeatured,
    url,
  } = article;

  return (
    <article>
      <ArticleCardTop
        isEditView={isEditView}
        publicationState={publicationState}
        publicationDate={publishedAt}
        isFeatured={isFeatured}
      />
      <Content $isFeatured={isFeatured}>
        <TextContainer>
          <Headline as={'h3'}>{content.title}</Headline>
          <Summary>{content.summary}</Summary>
          <ArticlePage>{content.source}</ArticlePage>
          {/* TODO PROCO-1420: External links - https://new-work.atlassian.net/browse/PROCO-1420 */}
          <Link href={url}>
            <FormattedMessage id="EP_READ_MORE" />
          </Link>
        </TextContainer>
        <ImageContainer>
          <img
            alt={content.cover.caption}
            src={content.cover.url}
            loading="lazy"
          />
        </ImageContainer>
      </Content>
      <ArticleCardBottom
        isEditView={isEditView}
        socialInteractions={socialInteractions}
      />
    </article>
  );
};
