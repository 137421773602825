import { useMutation } from '@apollo/client';
import { useState } from 'react';
import type { FC } from 'react';
import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TextButton } from '@xing-com/button';
import type { EntityPageQuery } from '@xing-com/crate-entity-pages-common';
import {
  useDialogContext,
  NotSavedDialog,
  usePageContext,
  useEditContext,
  EntityPageDocument,
  getEntityPageQueryVariables,
  EditHeader,
  useErrorContext,
} from '@xing-com/crate-entity-pages-common';
import { trackEditSaving } from '@xing-com/crate-entity-pages-common/src/tracking';
import { FormField } from '@xing-com/text-field';

import { EntityPageUpdateConfigurationDocument } from '../../../graphql/mutations/update-page-info.gql-types';
import * as Styled from './edit-page-info.styles';

const MAX_TITLE_CHARS = 80;
const MAX_SLOGAN_CHARS = 64;

export const EditPageInfo: FC = () => {
  const { executeWithDialog } = useDialogContext();
  const { pageContext, setPageContext } = usePageContext() ?? {};
  const [slogan, setSlogan] = useState(pageContext?.pageSlogan || '');
  const [title, setTitle] = useState(pageContext?.pageTitle || '');
  const intl = useIntl();

  const { isAdmin, trackingData } = useEditContext();
  const displayBanner = pageContext?.focusType === 'COMPANY';
  const userHasPermissionsToEditTitle = isAdmin;

  const pageSlug = pageContext?.pageSlug;

  const { dataChanged, setDataChanged, dialogConfirmation } =
    useDialogContext();

  const { showError } = useErrorContext();

  const handleTitleChange = (newTitle: string) => {
    if (newTitle.length <= MAX_TITLE_CHARS) {
      setDataChanged(true);
      setTitle(newTitle);
    }
  };

  const handleSloganChange = (newSlogan: string) => {
    if (newSlogan.length <= MAX_SLOGAN_CHARS) {
      setDataChanged(true);
      setSlogan(newSlogan);
    }
  };

  const [updatePageInfo, { loading }] = useMutation(
    EntityPageUpdateConfigurationDocument,
    {
      onCompleted: (data) => {
        if (data?.entityPageUpdateConfiguration?.error) {
          showError({
            message: 'EP_JOBS_FEEDBACK_ERROR',
            error: data.entityPageUpdateConfiguration.error,
          });
        } else {
          setDataChanged(false);
          pageContext?.goBackUrl && pageContext?.goBackUrl();
        }
      },
      onError: (error) =>
        showError({
          message: 'EP_JOBS_FEEDBACK_ERROR',
          error,
        }),
      update: (cache) => {
        const data = cache.readQuery<EntityPageQuery>({
          query: EntityPageDocument,
          variables: getEntityPageQueryVariables(pageContext?.pageSlug),
        });

        cache.writeQuery({
          query: EntityPageDocument,
          // @ts-expect-error TODO: fix this type
          variables: getEntityPageQueryVariables(pageContext?.pageSlug),
          data: {
            // @ts-expect-error TODO: fix this type
            entityPageEX: {
              ...(data?.entityPageEX || {}),
              title,
              slogan,
            },
          },
        });

        // @ts-expect-error TODO: fix setPageContext types
        setPageContext({
          ...pageContext,
          pageSlogan: slogan,
          pageTitle: title,
        });
      },
    }
  );

  const handleMutation = () => {
    if (!dataChanged) {
      pageContext?.goBackUrl && pageContext?.goBackUrl();
      return;
    }
    // remove extra spaces between words, end and beggining
    const mutationTitle = title.replace(/^\s+|\s+$/g, '');
    const mutationSlogan = slogan.replace(/^\s+|\s+$/g, '');

    updatePageInfo({
      variables: {
        // @ts-expect-error TODO: fix this type
        pageId: pageSlug,
        slogan: mutationSlogan.length > 0 ? mutationSlogan : null,
        ...(userHasPermissionsToEditTitle && {
          title: mutationTitle.length > 0 ? mutationTitle : null,
        }),
      },
    });
  };

  return (
    <>
      <EditHeader
        titleKey="EP_SLOGAN_EDIT_HEADLINE"
        userGuidanceCopy="EP_SLOGAN_EDIT_HEADLINE_INFO"
      />
      {userHasPermissionsToEditTitle && (
        <Styled.FormFieldWrapper data-cy="EDIT_PAGE_INFO_TITLE">
          {!displayBanner && (
            <FormField
              id="EDIT_PAGE_INFO_TITLE_INPUT"
              medium
              value={title}
              // @ts-expect-error FIXME: SC6
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleTitleChange(e.target.value)
              }
              label={intl.formatMessage({
                id: 'EP_TITLE_EDIT_LABEL',
                defaultMessage: 'EP_TITLE_EDIT_LABEL',
              })}
              helperText={
                title && title.length > 0
                  ? intl.formatMessage(
                      {
                        id: 'EP_INPUT_CHARS_LEFT',
                        defaultMessage: 'EP_INPUT_CHARS_LEFT',
                      },
                      { charactersNumber: MAX_TITLE_CHARS - title.length }
                    )
                  : intl.formatMessage({
                      id: 'EP_TITLE_EDIT_HINT',
                      defaultMessage: 'EP_TITLE_EDIT_HINT',
                    })
              }
            />
          )}
        </Styled.FormFieldWrapper>
      )}
      <Styled.FormFieldWrapper data-cy="EDIT_PAGE_INFO_SLOGAN">
        <FormField
          id="EDIT_PAGE_INFO_SLOGAN_INPUT"
          medium
          value={slogan}
          // @ts-expect-error FIXME: SC6
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleSloganChange(e.target.value)
          }
          label={intl.formatMessage({
            id: 'EP_SLOGAN_EDIT_LABEL',
            defaultMessage: 'EP_SLOGAN_EDIT_LABEL',
          })}
          helperText={
            slogan && slogan.length > 0
              ? intl.formatMessage(
                  {
                    id: 'EP_INPUT_CHARS_LEFT',
                    defaultMessage: 'EP_INPUT_CHARS_LEFT',
                  },
                  { charactersNumber: MAX_SLOGAN_CHARS - slogan.length }
                )
              : intl.formatMessage({
                  id: 'EP_SLOGAN_EDIT_HINT',
                  defaultMessage: 'EP_SLOGAN_EDIT_HINT',
                })
          }
        />
      </Styled.FormFieldWrapper>
      <Styled.ActionsWrapper>
        <TextButton
          size={'medium'}
          disabled={loading}
          onClick={() =>
            executeWithDialog(
              () => pageContext?.goBackUrl && pageContext?.goBackUrl()
            )
          }
          data-cy="DISCARD_PAGE_INFO_BUTTON"
        >
          <FormattedMessage
            id="EP_DISCARD_CTA"
            defaultMessage="EP_DISCARD_CTA"
          />
        </TextButton>
        <Styled.PublishButton
          loading={loading}
          size={'medium'}
          variant={'primary'}
          onClick={() => {
            if (trackingData && pageContext?.focusType && pageContext?.pageId) {
              trackEditSaving({
                focusType: pageContext?.focusType,
                itemId: pageContext?.pageId,
                module: trackingData.module,
                part: trackingData.part,
              });
            }
            handleMutation();
          }}
          data-cy="PUBLISH_PAGE_INFO_BUTTON"
          data-testid="PUBLISH_PAGE_INFO_BUTTON"
        >
          <FormattedMessage
            id="EP_PUBLISH_CTA"
            defaultMessage="EP_PUBLISH_CTA"
          />
        </Styled.PublishButton>
      </Styled.ActionsWrapper>
      {dataChanged && dialogConfirmation?.dialogAction && <NotSavedDialog />}
    </>
  );
};
