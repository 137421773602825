import styled from 'styled-components';

import {
  cornerRadiusXL,
  spaceXXS,
  spaceS,
  xdlColorMarkerMatch,
  xdlColorMarkerMatchText,
  xdlColorBackgroundTertiary,
  xdlColorTextOnLight,
} from '@xing-com/tokens';

import { iconWrapper } from '../styles';

export const HiddenFlag = styled.span`
  border-radius: ${cornerRadiusXL};

  ${iconWrapper};
`;

export const PublishedFlag = styled.span`
  color: ${xdlColorMarkerMatchText};
  background-color: ${xdlColorMarkerMatch};
  border-radius: ${cornerRadiusXL};
  padding: ${spaceXXS} ${spaceS};

  ${iconWrapper};
`;

export const ScheduledFlag = styled.span`
  color: ${xdlColorTextOnLight};
  background-color: ${xdlColorBackgroundTertiary};
  border-radius: ${cornerRadiusXL};
  padding: ${spaceXXS} ${spaceS};

  ${iconWrapper};
`;
