import type { ArticlesModuleCardNodeFragment } from '../graphql/fragments/articles-module-card.gql-types';
import type { ArticleCardView } from '../types/types';

export const createArticleCard = (
  node: ArticlesModuleCardNodeFragment
): ArticleCardView => {
  const { object, metadata } = node;

  const socialInteractions = {
    commentsCount: metadata.commentsCount,
    reactionsCount: metadata.starsCount,
    sharesCount: metadata.clicksCount ?? undefined, // TODO: we need to ask why this is optional
    viewsCount: metadata.readCount,
  };

  const sharedProperties = {
    globalId: object.globalId,
    id: object.id,
    socialInteractions: socialInteractions,
    publishedAt: object.publishedAt ?? undefined,
    updatedAt: object.updatedAt ?? undefined,
    isFeatured: node.isFeatured,
    publicationState: node.status,
  };

  if (object.__typename === 'ArticlesArticle') {
    const header = object.header;

    return {
      content: {
        title: header.title.text,
        summary: header.summary?.text ?? '', // TODO: we need to ask why this is optional
        source: object.visitUrl ?? '', // TODO: we need to ask why this is optional
        cover: {
          caption: header.cover?.caption?.text ?? '', // TODO: we need to ask why this is optional
          ...(header.cover?.__typename === 'ArticleCoverImage'
            ? {
                url: header.cover.url ?? '',
                isVideo: false,
              }
            : {
                url:
                  header.cover?.video?.customThumbnails?.[0]?.sources?.[0]
                    .source ??
                  header.cover?.video?.defaultThumbnails?.[0]?.sources?.[0]
                    .source ??
                  '',
                isVideo: true,
              }),
        },
      },
      url: object.visitUrl ?? '', // TODO: we need to ask why this is optional
      ...sharedProperties,
    };
  } else {
    return {
      content: {
        title: object.title ?? '', // TODO: we need to ask why this is optional
        summary: object.description ?? '', // TODO: we need to ask why this is optional
        source: object.source,
        cover: {
          url: object.image.srcWide2x ?? object.image.srcWide ?? '', // TODO: we need to ask what to do in this case,
          caption: 'needs_to_be_implemented',
          isVideo: object.hasVideo,
        },
      },
      url: object.url,
      ...sharedProperties,
    };
  }
};

export const createArticleCards = (
  nodes: ArticlesModuleCardNodeFragment[]
): ArticleCardView[] => nodes.map((node) => createArticleCard(node));
