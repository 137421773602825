import { useQuery } from '@apollo/client';
import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';
import {
  ModuleHeader,
  useEditContext,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';
import { IconPlus, IconLink } from '@xing-com/icons';

import { ArticleCard } from '../components/article-card/article-card';
import type { NavigationStatusOptions } from '../components/filter-bar/filter-bar';
import {
  OLD_NEWS_NEW_ARTICLE,
  OLD_NEWS_NEW_ARTICLE_LINK,
} from '../constants/urls';
import { EntityPagesArticleModuleListDocument } from '../graphql/queries/articles-list.gql-types';
import { createArticleCards } from '../models/article-card';
import * as Styled from './articles-module.styles';

const LIMIT = 3;

export const ArticlesModule: FC = () => {
  const { $t } = useIntl();
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext() ?? {};

  const pageCppId = pageContext?.cppId ?? undefined;
  const globalId = `surn:x-xing:content:publisher_page:${pageCppId}`;

  const [loadingMore, setLoadingMore] = useState(false);

  const { data, loading, error, refetch, fetchMore } = useQuery(
    EntityPagesArticleModuleListDocument,
    {
      variables: {
        pageUrn: globalId,
        first: LIMIT,
        status: ContentArticleStatus.Published,
      },
    }
  );

  const articlesEdges = data?.contentPageArticles?.edges;
  const articlesNodes =
    articlesEdges
      ?.map((edge) => edge?.node)
      .filter((node) => node !== undefined) ?? [];
  const articlesCards = createArticleCards(articlesNodes);

  const isEmptyState = articlesCards.length === 0;
  const isShowMoreAvailable = data?.contentPageArticles?.pageInfo.hasNextPage;

  const oldNewsNewArticleLink =
    pageCppId && OLD_NEWS_NEW_ARTICLE_LINK(pageCppId);
  const oldNewsNewArticle = pageCppId && OLD_NEWS_NEW_ARTICLE(pageCppId);

  const handleOnFilter = (navigationStatus: NavigationStatusOptions) => {
    refetch({
      pageUrn: globalId,
      // @ts-expect-error TODO: the query needs to be updated with the new status and use the correct type
      status: navigationStatus,
    });
  };
  const handleShowMoreArticles = () => {
    setLoadingMore(true);
    fetchMore({
      variables: {
        after: data?.contentPageArticles?.pageInfo.endCursor,
      },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        setLoadingMore(false);
        if (!fetchMoreResult) return prev;
        return {
          contentPageArticles: {
            ...fetchMoreResult.contentPageArticles,
            edges: [
              ...(prev?.contentPageArticles?.edges ?? []),
              ...(fetchMoreResult?.contentPageArticles?.edges ?? []),
            ],
          },
        };
      },
    });
  };

  return (
    <>
      <ModuleHeader
        headlineCopyKey="EP_ARTICLES_MODULE_HEADLINE"
        CustomEditButton={[
          <Styled.HeaderEditButton
            key="EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON"
            data-testid="EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON"
            to={oldNewsNewArticle}
            icon={IconPlus}
          >
            {$t({ id: 'EP-ARTICLES-MODULE-WRITE-NEW-ARTICLE-BUTTON' })}
          </Styled.HeaderEditButton>,
          <Styled.HeaderEditButton
            key="EP-ARTICLES-MODULE-SHARE-NEW-ARTICLE-BUTTON"
            data-testid="EP-ARTICLES-MODULE-SHARE-NEW-ARTICLE-BUTTON"
            to={oldNewsNewArticleLink}
            icon={IconLink}
          >
            {$t({ id: 'EP-ARTICLES-MODULE-SHARE-NEW-ARTICLE-BUTTON' })}
          </Styled.HeaderEditButton>,
        ]}
      />

      {isEditing && (
        <Styled.FilterBar
          allAmount={0}
          publishedAmount={0}
          scheduledAmount={0}
          draftsAmount={0}
          hiddenAmount={0}
          onNavigation={handleOnFilter}
        />
      )}

      {error ? (
        <div>error</div>
      ) : loading ? (
        <div>loading</div>
      ) : isEmptyState ? (
        <div>empty</div>
      ) : (
        <Styled.ArticlesGrid data-testid="EP-ARTICLES-MODULE-ARTICLES-GRID">
          {articlesCards.map((article) => (
            <ArticleCard article={article} key={article.id} />
          ))}
        </Styled.ArticlesGrid>
      )}

      {isShowMoreAvailable && (
        <Styled.ArticlesModuleFooter>
          <Button
            data-testid="EP-ARTICLES-MODULE-SHOW-MORE-ARTICLES-BUTTON"
            variant="secondary"
            loading={loadingMore}
            onClick={handleShowMoreArticles}
          >
            {$t({ id: 'EP-ARTICLES-MODULE-SHOW-MORE-ARTICLES-BUTTON' })}
          </Button>
        </Styled.ArticlesModuleFooter>
      )}
    </>
  );
};
