import { FormattedMessage } from 'react-intl';

import { IconLikeFilled, IconShow } from '@xing-com/icons';
import { SocialBar } from '@xing-com/social-bar';

import type { ArticleCardSocialInteractions } from '../../../types/types';
import { ICON_DIMENSION } from '../constants';
import {
  IconTextWrapper,
  SocialInfoLeft,
  SocialInfoRight,
  SocialInfoWrapper,
} from './article-card-bottom.styles';

type Props = {
  isEditView: boolean;
  socialInteractions: ArticleCardSocialInteractions;
};

export const ArticleCardBottom: React.FC<Props> = ({
  isEditView,
  socialInteractions,
}) => {
  return (
    <>
      <SocialInfoWrapper>
        <SocialInfoLeft>
          <IconTextWrapper>
            <IconLikeFilled width={ICON_DIMENSION} height={ICON_DIMENSION} />
            <span>{socialInteractions.reactionsCount}</span>
          </IconTextWrapper>
          {isEditView ? (
            <>
              <span>
                <FormattedMessage
                  id="EP_ARTICLES_SOCIAL_COMMENTS_COUNTER_LABEL"
                  values={{ counter: socialInteractions.commentsCount }}
                />
              </span>
              <span>
                <FormattedMessage
                  id="EP_ARTICLES_SOCIAL_SHARES_COUNTER_LABEL"
                  values={{ counter: socialInteractions.sharesCount }}
                />
              </span>
            </>
          ) : null}
        </SocialInfoLeft>
        {isEditView ? (
          <SocialInfoRight>
            <IconTextWrapper>
              <IconShow width={ICON_DIMENSION} height={ICON_DIMENSION} />
              <span>{`${socialInteractions.viewsCount}`}</span>
            </IconTextWrapper>
          </SocialInfoRight>
        ) : null}
      </SocialInfoWrapper>
      {!isEditView ? (
        //TODO PROCO-1419: Adjust the Social Bar / https://new-work.atlassian.net/browse/PROCO-1419
        <SocialBar
          urn={'URN'}
          onCommentClick={() => console.log('What is next?')}
          hasShare={true}
          hasReaction={true}
          hasComment={true}
          propChannel="wbm/EntityPages"
          entryPoint="entity_page_social_share"
        />
      ) : null}
    </>
  );
};
