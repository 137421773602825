import { useState, type FC } from 'react';
import { useIntl } from 'react-intl';

import { ContentSwitcher } from '@xing-com/content-switcher';
import { ContentArticleStatus } from '@xing-com/crate-common-graphql-types';

export type NavigationStatusOptions =
  | ContentArticleStatus.Published
  | ContentArticleStatus.Scheduled
  | ContentArticleStatus.Draft
  | ContentArticleStatus.Disabled
  | 'All';

type FilterBarProps = {
  allAmount: number;
  publishedAmount?: number;
  scheduledAmount?: number;
  draftsAmount?: number;
  hiddenAmount?: number;
  defaultActiveItemIndex?: number;
  className?: string;
  onNavigation?: (navigationStatus: NavigationStatusOptions) => void;
};
export const FilterBar: FC<FilterBarProps> = ({
  allAmount,
  publishedAmount = 0,
  scheduledAmount = 0,
  draftsAmount = 0,
  hiddenAmount = 0,
  defaultActiveItemIndex = 0,
  className,
  onNavigation = () => undefined,
}) => {
  const { $t } = useIntl();

  const [activeItemIndex, setActiveItemIndex] = useState(
    defaultActiveItemIndex
  );

  const handleOnClicked = (
    index: number,
    navigationStatus: NavigationStatusOptions | 'All'
  ) => {
    setActiveItemIndex(index);
    onNavigation(navigationStatus);
  };

  const contentSwitcherData = [
    {
      copy: $t({ id: 'EP-ARTICLES-MODULE-FILTER-ALL' }) + ` (${allAmount})`,
      onClick: () => handleOnClicked(0, 'All'),
    },
    {
      copy:
        $t({ id: 'EP-ARTICLES-MODULE-FILTER-PUBLISHED' }) +
        ` (${publishedAmount})`,
      onClick: () => handleOnClicked(1, ContentArticleStatus.Published),
    },
    {
      copy:
        $t({ id: 'EP-ARTICLES-MODULE-FILTER-SCHEDULED' }) +
        ` (${scheduledAmount})`,
      onClick: () => handleOnClicked(2, ContentArticleStatus.Scheduled),
    },
    {
      copy:
        $t({ id: 'EP-ARTICLES-MODULE-FILTER-DRAFTS' }) + ` (${draftsAmount})`,
      onClick: () => handleOnClicked(3, ContentArticleStatus.Draft),
    },
    {
      copy:
        $t({ id: 'EP-ARTICLES-MODULE-FILTER-HIDDEN' }) + ` (${hiddenAmount})`,
      onClick: () => handleOnClicked(4, ContentArticleStatus.Disabled),
    },
  ];

  return (
    <ContentSwitcher
      className={className}
      data={contentSwitcherData}
      activeItemIndex={activeItemIndex}
    />
  );
};
