import styled from 'styled-components';

import {
  scale060,
  scale080,
  spaceL,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import { dotSeparator } from '../styles';

export const ArticleCardTopWrapper = styled.div`
  display: flex;
  gap: ${spaceL};
  font-size: ${scale060};
  line-height: ${scale080};
  color: ${xdlColorTextSecondary};
`;

export const InfoContainer = styled.div`
  flex: 1 1 auto;

  ${dotSeparator}
`;

export const ButtonsContainer = styled.div`
  flex: 0 0 auto;
`;
